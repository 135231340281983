// Use This for testing
const URL = "srt.insafeservices.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India, CBD Belapur Navi Mumbai ",
  "Bank A/c. No.": "39813124209",
  "IFSC Code": "SBIN0061520",
};

export const weightUncertaintySupportiveValues ={
  barometer: 2,
  tempIndicator:0.4,
  rhIndicator:1.9,
  weightBox:7950,
};

export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Summary Report","Courier Register","History Card","Expenses","Customer Complaints","Customer Feedback","Audit","Document Upload","Purchase"
,"Payment","DUC Master","Department","Request Form","Attendance","MarkAttendance","CoordinatesAddress","Client Complaints","Client Feedback","Settings","Datasheet Template","OfflineMode","Supplier Invoice","Department Analytics","WCR Report","Training Resources","Inward Register","Discipline","Assigned Duc","User"]; 

export const DEVICE_TYPE = ["desktop"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6"
